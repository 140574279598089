.wrapper
	width: 18px
	height: 18px
	padding: 7.5px
	display: flex
	flex-direction: row
	justify-content: space-between
	align-items: center
	margin: 0 auto
	font-weight: 500
	:global
		.MuiTypography-root
			display: none
	&svg
		width: 100%
		height: 100%
	

	

.active
	background-color: #D1F3E7
	color: #0F8E68
	
.waiting
	background-color: #E1E2EC
	color: #44464F

.cancel
	background-color: #FFDAD6
	color: #BA1A1A


	.wrapper
		justify-content: center


:global
	.status__wrapper
		&.wrapper
			border-radius: none
	.table__full
		.wrapper
			min-width: 74px
			display: flex
			flex-direction: row
			justify-content: center
			align-items: center
			gap: 8px
			padding: 6px 30px 6px 16px
			margin: 0
			svg
				width: 15px
				height: 15px
			.MuiTypography-root
				font-size: 14px
				line-height: 20px