//Fonts
$primary-font: "Roboto", sans-serif

//Colors
$primary-blue: #185AC2                //Light
$secondary-blue: #001944              //dark
$tertiary-blue: #00296F               //main
$success: #1EBD8D                     //Light
$success-dark: #0F8E68                //Light
$error: #BA1A1A                       //Light

$background: #141423                  //Light
$background-gray: #E2EAF7             //Light
$outline: #BDC1D2                     //Light
$surface: #FFFFFF                     //Light
$surface-alt: #E1E2EC                 //Light

$primary-container: #D9E2FF           //Light
$secondary-container: #CDE5FF         //Light
$tertiary-container: #BBE9FF          //Light
$error-container: #FFDAD6             //Light
$success-container: #D1F3E7           //Light

$on-surface: #1B1B1F                  //Light
$on-surface-alt: #44464F              //Light
$on-surface-alt2: #90939B             //Light
$on-container: #FFFFFF                //Light
$on-container-hover: #CDD4F0          //Light
$on-sidemenu: #A4B1EA                 //Light
$devider: #E9ECF5                     //Light
$slider-background: #F3F6FE           //Light

