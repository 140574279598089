@import '../shared/assets/styles/index.sass';
@import '../../node_modules/@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
@import '../../node_modules/react-calendar/dist/Calendar.css';
@import '../../node_modules/react-date-picker/dist/DatePicker.css';

html {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  background: #e2eaf7;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.MuiContainer-root {
  padding: 0.75rem;
}

.MuiFormControl-root {
  .MuiInputBase-root.Mui-error {
    position: relative;
  }
  .MuiInputBase-root.Mui-error::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    width: 48px;
    height: 48px;
    background-image: url('../shared/assets/icons/invalid-input.svg');
    background-repeat: no-repeat;
  }
}

.MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
  left: 0;
}

.MuiBox-root {
  max-width: 100%;
  /* max-height: 100%; */
}

.MuiDataGrid-main {
  border-radius: 16px;
  border: 1px solid #bdc1d2;
  max-height: 800px;
}

.MuiDataGrid-row {
  border-top: 1px solid #bdc1d2;
}
.MuiDataGrid-row:first-child {
  border-top: none;
}

.MuiDataGrid-columnHeaders {
  /* border-bottom: 2px solid #bdc1d2; */
}
