@import 'shared/assets/styles/variables.sass'

.container
  max-width: 100vw
  min-height: 100vh
  min-width: 375px
  display: flex
  flex-direction: column
  justify-content: center
  background-color: $background-gray
  
  

