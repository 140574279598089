.tablePagination
	:global
		.MuiOutlinedInput-root

		.MuiSelect-select
			padding: 6px 14px !important
			line-height: 20px
			font-family: Roboto
			font-size: 14px
			font-style: normal
			font-weight: 400
			line-height: 20px
			letter-spacing: 0.035px

		.MuiSelect-icon
			right: 14px

		.Mui-selected
			background-color: #D9E2FF
			color: #185AC2

		.Mui-selected:hover
			background-color: #c3d1ff
			color: #0b50be

		.MuiPaginationItem-root:hover
			background-color: #c3d1ff
