.modal
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  background-color: #141423

.paper
  padding: 30px 24px
  border-radius: 16px
  max-width: 413px
  position: relative
  overflow: hidden
  margin: auto
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
