.container 
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	
.title
	font-size: 1.5rem
	line-height: 2rem

.subtitle
	font-size: .875rem
	line-height: 1.25rem

.subButton
	color: #90939B
	position: relative
	margin: 21px auto 0
	cursor: pointer
	font-size: 14px
	line-height: 20px
	&:after 
		content: ''
		position: absolute
		left: -29px
		top: 63%
		transform: translate(0%, -50%)
		width: 18px
		height: 18px
		background-image: url('../../../../shared/assets/icons/back-arrow.svg')
		background-repeat: no-repeat

.success
	:global
		.MuiTextField-root
			.MuiOutlinedInput-notchedOutline
				border-color: #1EBD8D
	&:after
		content: ''
		position: absolute
		top: 4px
		right: 0
		width: 48px
		height: 48px
		background-image: url('../../../../shared/assets/icons/success.svg')
		background-repeat: no-repeat
		background-position: 50% 50%
		background-size: 24px 24px