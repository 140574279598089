.container 
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)

.title
	font-size: 1.5rem
	line-height: 2rem

.subtitle
	font-size: .875rem
	line-height: 1.25rem

.isDark
	background-color: #141423